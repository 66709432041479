import React from "react";
import Arrow from "assets/ci_arrow-right-lg (1).svg";
import Amazon from "assets/client/amazon.svg";
import Google from "assets/client/google.svg";
import Microsoft from "assets/client/microsoft.svg";
import Youtube from "assets/client/youtube.svg";
import Bloomberg from "assets/client/bloomberg.svg";
import Spotify from "assets/client/spotify.svg";
import { Virtual, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "gatsby";
const GlobalBrand = () => {
  const Partnerdata = [
    { image: Amazon },
    { image: Google },
    { image: Microsoft },
    { image: Youtube },
    { image: Bloomberg },
    { image: Spotify },
  ];
  return (
    <div className="  pt-5 sm:p-6 md:py-24 pb-8 ">
      <div className=" max-w-5xl mx-auto p-4 ">
        <div className="lg:flex ">
          <div className="md:w-3/4 flex flex-col items-center md:items-start justify-center md:justify-start">
            <h1 className=" text-4xl font-primary font-semibold leading-[60px] text-center md:text-left">
              Long-Term Success With Global Brands
            </h1>

            <p className=" mt-8 text-lg font-normal font-normal leading-6 text-center md:text-left">
              We understand that building long-term relationships requires a
              long-term investment. For us, this means industry research and
              continuous collection of customer feedback to better understand
              your needs and preferences.
            </p>
            <p className=" mt-8 text-lg font-normal font-normal leading-6 text-center md:text-left">
              Our dedication to excellence has positioned us as a go-to language
              provider for major global brands. With us, you gain more than a
              service—you get a partnership focused on your growth.
            </p>

            <Link to="/our-customers">
              <button className=" mt-12 flex text-[#649df2] text-semibold text-lg leading-6 font-opensans ">
                See our global brands
                <img src={Arrow} alt="" className="mt-[2px] ml-2" />
              </button>
            </Link>
          </div>
          <div></div>
        </div>
        <div className="lg:block hidden ">
          <div className=" flex flex-wrap justify-between gap-12 md:gap-4 mt-16 ">
            <img src={Amazon} alt="" />
            <img src={Google} alt="" />
            <img src={Microsoft} alt="" />
            <img src={Youtube} alt="" />
            <img src={Bloomberg} alt="" />
            <img src={Spotify} alt="" />
          </div>
        </div>
      </div>
      <div className="lg:hidden block  2xl:px-4 pt-5 2xl:p-6 md:py-4 pb-8 2xl:max-w-7xl mx-auto">
        <div className="">
          <Swiper
            modules={[Virtual, Navigation]}
            spaceBetween={30}
            virtual
            breakpoints={{
              1024: {
                slidesPerView: 6,
              },
              767: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              332: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
            }}
            style={{
              paddingTop: "80px",
              paddingBottom: "80px",

              marginLeft: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // overflow: "hidden",
            }}
          >
            {Partnerdata.map((slideContent, index) => (
              <SwiperSlide key={slideContent} virtualIndex={index}>
                <div className="w-max ml-12 ">
                  <img src={slideContent.image} alt="" className="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default GlobalBrand;
