import React from "react";
import Cta from "assets/ctaimg.png";
import { Link } from "gatsby";

const LanguageSolution = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Cta})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "auto",
  };

  return (
    <div className="my-12">
      <div
        className="max-w-7xl mx-auto p-4 rounded-lg pb-24"
        style={backgroundImageStyle}
      >
        <div className="flex items-center ">
          <div className="text-white mt-16 ml-4 md:ml-14 md:mt-24  ">
            <h1 className="text-4xl font-primary font-semibold leading-[54px] text-center md:text-left ">
              Language Solutions Made for Your Needs
            </h1>
            <p className="mt-6 text-lg leading-7 font-normal font-opensans max-w-2xl text-center md:text-left ">
              Our customer support team is available 24/7. Ready to experience
              language services truly made for you? Get in touch with us today.
              We’re here to help.
            </p>
            <div className="mt-6 md:flex  gap-4 px-6 md:px-0">
              <button className="bg-[#ff7b16] px-6 py-2 rounded-md w-full md:w-max ">
                <Link to="/quote"> Get Started </Link>
              </button>
              <button className="mt-4 md:mt-0 border border-white px-6 py-2 rounded-md w-full md:w-max">
                <Link to="/contactus.php">Contact Sales</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSolution;
