import Explorance from "assets/exploranc.png";
import Stimg from "assets/stimg.svg";
import Acimg from "assets/acimg.svg";
import Kbimg from "assets/kbimg.svg";
export const TestimonialData = [
  {
    imgUrl: Explorance,
    title: "",
    description:
      "Our Company has been working with them for a long time and their services are impeccable. The communication is great and they are always willing to help handle urgent requests. Thank you!",
    image: Stimg,
    heading: "Sandrine Thiboutot",
    subheading: "Content Marketing Specialist",
  },
  {
    imgUrl: "",
    title: "ERP-FAST CONSULTING",
    description:
      "High end professional service, you can fully trust their services for any challenging translation like medical, legal, immigration etc. When it’s mission critical you need them and they deliver exceptionally, rock solid.",
    image: Acimg,
    heading: "Antonio Carbone",
    subheading: "Founder and Owner",
  },
  {
    imgUrl: "",
    title: "BRAND ZOO",
    description:
      "Tomedes had great customer service. Their communications with me were super prompt and they got my project done super quickly. I highly recommend if you need translation and apostille services!!",
    image: Kbimg,
    heading: "Kathy Battat",
    subheading: "Account and Operations Manager",
  },
];

export const LifeEasierdata = [
  { text: "Peace of mind every time with our 1-Year Quality Guarantee." },
  {
    text: "Meet urgent deadlines without compromising quality with Fast Delivery.",
  },
  {
    text: "Make informed decisions with the help of our dedicated project managers.",
  },
  {
    text: "Get language services anytime, anywhere with our 24/7 Customer Support.",
  },
  {
    text: "Enjoy transparent pricing plans that fit your budget.",
  },
  {
    text: "It's all part of our commitment to customer satisfaction.",
  },
];
