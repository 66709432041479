import QualityGurantee from "components/YearGuarantee/quality-gurantee";
import React from "react";
import Hero from "assets/heroconcentricity.svg";
import { Link } from "gatsby";
import DeliveryMeans from "components/FastDelivery/deliveryMeans";
import Arrow from "assets/rightarrow.svg";
import Mask from "assets/blkimg1.svg";
import PersonalService from "./personalService";
import Mask1 from "assets/blkimg.svg";
import Mask2 from "assets/blkimg 12.svg";
import ClientTestimonial from "components/YearGuarantee/clientTestimonial";
import { TestimonialData } from "./config";
import Decision from "./decision";
import LifeEasier from "./lifeEasier";
import GlobalBrand from "./globalBrand";
import LanguageSolution from "./languageSolution";

const Concentricity = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const buttonContent = (
    <button className="border border-[#ffffff] px-4 py-2 rounded-sm w-full md:w-max">
      Contact us
    </button>
  );

  const buttonDelivery = (
    <button className=" mt-12 flex text-[#2a7cf8] text-semibold text-lg leading-6 font-opensans">
      Schedule a Call
      <img
        src={Arrow}
        alt=""
        className="mt-[3px]"
        style={{
          filter:
            "brightness(0) saturate(100%) invert(65%) sepia(98%) saturate(6300%) hue-rotate(201deg) brightness(91%) contrast(105%)",
        }}
      />
    </button>
  );
  const Learnmorebutton = (
    <button className=" mt-12 flex text-[#2a7cf8] text-semibold text-lg leading-6 font-opensans">
      Recent customized projects
      <img
        src={Arrow}
        alt=""
        className="mt-[3px]"
        style={{
          filter:
            "brightness(0) saturate(100%) invert(65%) sepia(98%) saturate(6300%) hue-rotate(201deg) brightness(91%) contrast(105%)",
        }}
      />
    </button>
  );

  // const btntext = (
  //   <p className="text-[#00173A] text-semibold ">See Testimonials</p>
  // );
  return (
    <div>
      <section
        className="px-4  py-12 lg:h-[600px] flex items-center"
        style={backgroundImageStyle}
      >
        <QualityGurantee
          text="Tomedes Customer Centricity"
          heading=" Language Solutions Driven By Your Needs"
          subheading="At Tomedes, you are the heart of everything we do. When you speak, we take the time to listen, understand, and adapt our solutions to fit your needs. Beyond exceptional service, we aim to build a lasting relationship with you."
          button={buttonContent}
        />
      </section>
      <div className="w-11/12 max-w-7xl mx-auto flex flex-col">
        <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
          <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
            <Link to="/">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                alt="home"
              />
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />
            <Link to="/about-tomedes">
              <span>About</span>
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />
            <span>Customer Centricity</span>
          </div>
        </div>
      </div>

      <DeliveryMeans
        heading="Our Focus Is Your Needs"
        subheading="Your satisfaction guides our actions. From your initial consultation, we focus on deeply understanding your unique language needs."
        text="We’ll gather your requirements, preferences and goals, creating a detailed plan. This ensures your voice is heard and lays the groundwork for your success. "
        button={buttonDelivery}
        Image={Mask}
        link="/contactus.php"
      />
      <div className="bg-[#f5f9ff] text-[#00173A] py-16">
        <PersonalService
          heading="Because You Deserve Personalized Service"
          subheading="Our solutions are determined by your needs. We use your project plan as a guide to create custom solutions that fit you."
          text="We optimize projects with data-driven decisions, saving time and ensuring your success. We tailor solutions because you deserve better than one-size-fits-all."
          button={Learnmorebutton}
          Image={Mask1}
          link="/recent-translations.php"
        />
      </div>
      <DeliveryMeans
        heading="We’re Invested In Your Success"
        subheading="We’re honored to share your success. When you win, we win. Open communication and collaboration is how we align with your goals. "
        text="Together, we’re capable of achieving your goals, and along the way, we’ll celebrate each milestone and success together."
        // button={buttonDelivery}
        Image={Mask2}
      />
      <div className="bg-[#ffefe2]">
        <ClientTestimonial
          text="Client Testimonials"
          description=" Discover what our clients have to say about the reliability and
              responsiveness of our customer support. Their stories speak to the
              immense value of having a dedicated support system."
          btntext="See Testimonials"
          testimoniladata={TestimonialData}
          bgClass="bg-[#ffefe2]"
        />
      </div>
      <Decision />
      <div className="bg-[#00173A]">
        <LifeEasier />
      </div>
      <GlobalBrand />
      <LanguageSolution />
    </div>
  );
};
export default Concentricity;
