import React from "react";
import Check from "assets/check-circle.svg";
import { Link } from "gatsby";

const LifeEasier = () => {
  return (
    <div className="px-4 py-12">
      <div className=" sm:px-6 max-w-5xl mx-auto text-[#00173A] ">
        <h1 className="text-4xl font-primary font-semibold text-white leading-[60px]">
          We’re Committed to Making Your Life Easier
        </h1>
        <div className="lg:flex justify-between items-center gap-16 mt-10">
          <div className="flex flex-col gap-12">
            <div className="flex gap-4">
              <img src={Check} alt="" />
              <p className="text-white font-opensans text-lg font-normal leading-7">
                Peace of mind every time with our
                <span className="text-[#649df2] cursor-pointer">
                  {" "}
                  <Link to="/about/1-year-guarantee">
                    1-Year Quality Guarantee.
                  </Link>
                </span>
              </p>
            </div>
            <div className="flex gap-4">
              <img src={Check} alt="" />
              <p className="text-white font-opensans text-lg font-normal leading-7">
                Meet urgent deadlines without compromising quality with
                <span className="text-[#649df2] cursor-pointer">
                  {" "}
                  <Link to="/about/fast-delivery">Fast Delivery.</Link>
                </span>
              </p>
            </div>
            <div className="flex gap-4">
              <img src={Check} alt="" />
              <p className="text-white font-opensans text-lg font-normal leading-7">
                Make informed decisions with the help of our dedicated project
                managers.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-12 mt-10 lg:mt-0 ">
            <div className="flex gap-4">
              <img src={Check} alt="" />
              <p className="text-white font-opensans text-lg font-normal leading-7">
                Get language services anytime, anywhere with our
                <span className="text-[#649df2] cursor-pointer">
                  <Link to="/about/available-24/7"> 24/7 Customer</Link>
                  Support.
                </span>
              </p>
            </div>
            <div className="flex gap-4">
              <img src={Check} alt="" />
              <p className="text-white font-opensans text-lg font-normal leading-7">
                Enjoy{" "}
                <span className="text-[#649df2]"> transparent pricing </span>
                plans that fit your budget.
              </p>
            </div>
            <div className="flex gap-4">
              <img src={Check} alt="" />
              <p className="text-white font-opensans text-lg font-normal leading-7">
                It's all part of our commitment to customer satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LifeEasier;
