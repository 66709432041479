import Concentricity from "components/Customerconcentricity";
import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const CustomerConcentricity = () => {
  const description =
    "We guarantee personalized language solutions, blending innovative processes with accessible 24/7 communication channels. Explore our customer-centric approach.";
  const title = `Customer-Centric Language Service Company`;
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/about/customer-centricity"
      />
      <Concentricity />
    </Layout>
  );
};
export default CustomerConcentricity;
