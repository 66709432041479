import React from "react";
import Blogimg from "assets/blogimg.svg";
import Videoimg from "assets/videoimg.svg";
import Studyimg from "assets/studyimg.svg";
import { Link } from "gatsby";

const Decision = () => {
  return (
    <div className="px-4">
      <div className="pt-5 sm:px-6 md:py-12 max-w-5xl mx-auto text-[#00173A] py-16">
        <h1 className="text-[#00173a] font-primary text-4xl leading-[60px] font-semibold text-center">
          Make Well-Informed Decisions With Tomedes
        </h1>
        <p className="text-[#00173a] font-normal leading-7 font-opensans text-center text-lg py-8">
          We believe in the power of customer education. After all, knowledge is
          power. That’s why we equip you with the latest information. Explore
          our collection of resources. Build your knowledge and make
          well-informed decisions.
        </p>
        <div className="grid lg:grid-cols-2 gap-11 mt-16">
          <Link to="/translator-hub.php">
            <div
              className="relative rounded-xl bg-cover h-[400px] lg:h-full bg-center text-white text-3xl font-primary font-semibold p-8 cursor-pointer"
              style={{
                backgroundImage: `url(${Blogimg})`,
              }}
            >
              <p className="absolute bottom-10 cursor-pointer">Blogs</p>
            </div>
          </Link>
          <div className="flex flex-col gap-10">
            <Link to="https://www.youtube.com/channel/UC9k2By4XhHEjI6usn0nJfNw">
              <div
                className="cursor-pointer relative rounded-xl  bg-cover bg-center h-48 text-white text-3xl font-primary font-semibold p-8"
                style={{ backgroundImage: `url(${Videoimg})` }}
              >
                <p className="absolute bottom-4 ">Videos</p>
              </div>
            </Link>
            <Link to="/recent-translations.php">
              <div
                className="cursor-pointer relative rounded-xl bg-cover bg-center h-48 text-white text-3xl font-primary font-semibold p-8"
                style={{ backgroundImage: `url(${Studyimg})` }}
              >
                <p className="absolute bottom-4">Case Studies</p>{" "}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decision;
